import React, { Fragment, useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SlideGrease from "../components/newsletter/SlideGrease";
import { SEO } from "../components/SEO";
import Footer from "../components/Footer";

const Newsletter = ({ data: { sanityNewsletter } }) => {
  const [firstPin, setFirstPin] = useState(null);
  const [lastPin, setLastPin] = useState(null);
  useEffect(() => {
    if (sanityNewsletter?._rawContent) {
      setFirstPin(
        sanityNewsletter?._rawContent.findIndex((item) => item?._type === "pin")
      );
      setLastPin(
        sanityNewsletter?._rawContent
          .map((item) => item?._type)
          .lastIndexOf("pin")
      );
    }
  }, [sanityNewsletter?._rawContent]);
  return (
    <Fragment>
      <Layout>
        <section className="bg-black pt-7 sm:pt-16 pb-[120px] bg-[url(../images/speaking/header-ribbon.svg)] bg-[right_bottom_-0.5rem] bg-[length:216px_250px]  sm:bg-[length:316px_310px] bg-no-repeat">
          <div className={"container "}>
            <h1
              className={
                "text-[#FFFF95] text-3xl sm:text-[48px] leading-[61px] font-lora"
              }
            >
              {sanityNewsletter?.title}
            </h1>
            <p className="font-lora text-[20px] leading-[25px] text-white italic">
              {sanityNewsletter?.publishedDate}
            </p>
          </div>
        </section>
        <section className="-mt-11 mb-[80px]">
          <SlideGrease
            topContent={sanityNewsletter?.topContent}
            pageContent={sanityNewsletter?._rawContent}
            firstPin={firstPin}
            lastPin={lastPin}
          />
        </section>
      </Layout>
      <Footer />
    </Fragment>
  );
};

export default Newsletter;
export const Head = () => <SEO />;
export const query = graphql`
  query ($slug: String!) {
    sanityNewsletter(slug: { current: { eq: $slug } }) {
      title
      publishedDate(formatString: "MMMM D YYYY")
      topContent {
        title
        thumbnail {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 490)
          }
        }
        _rawContent
      }
      _rawContent
    }
  }
`;
