import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactPortableText from "react-portable-text";
import { PortableText } from "@portabletext/react";

export default function SlideGrease({
  topContent,
  pageContent,
  firstPin,
  lastPin,
}) {
  return (
    <div className="max-w-[1275px] mx-auto bg-[#F9F9F9] rounded-[50px]  pb-[58px] pt-[90px] px-5 md:px-[50px] lg:px-[30px]">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6 md:gap-[65px]">
        <div className="flex col-span-7 sm:mt-14 sm:justify-end">
          <div className="max-w-[531px]">
            <h2 className="font-lora text-[32px] leading-[40px] font-semibold">
              {topContent?.title}
            </h2>
            <div className="mt-7">
              <ReactPortableText
                className="text-xl leading-[25px] prose max-w-none font-normal"
                projectId="p5dzjrg7"
                dataset="production"
                content={topContent._rawContent}
                serializers={{
                  ul: ({ children }) => (
                    <ul className="relative list-none mt-[11px]">{children}</ul>
                  ),
                  li: ({ children }) => (
                    <li className="relative text-base leading-5 mx-0 my-1 before:content-[''] before:absolute before:top-[6px] before:-left-[20px] before:h-[8px] before:w-[8px] before:rounded-full before:bg-black before:inline-block">
                      {children}
                    </li>
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="relative col-span-5 ml-10">
          <GatsbyImage
            image={topContent?.thumbnail?.asset?.gatsbyImageData}
            alt={topContent?.title}
          />
        </div>
      </div>
      <div className="max-w-[886px] mx-auto">
        <div className="mt-[88px]">
          <div className="font-lora text-base leading-[20px] font-normal prose max-w-none newsletter-b">
            <PortableText
              value={pageContent}
              components={{
                types: {
                  pin: (item) => (
                    <div
                      className={`${item?.index === firstPin && "mt-[48px]"} ${
                        item?.index === lastPin && "pb-10"
                      }`}
                    >
                      <div className="bg-[#d9d9d9]/[0.28] mb-[14px] rounded-[10px] text-base font-poppin font-medium leading-6 py-[22px] text-[#004867] px-8">
                        📌 {item?.value?.pin}{" "}
                        <span className="text-black">
                          ({item?.value?.compay})
                        </span>
                      </div>
                    </div>
                  ),
                },
                block: {
                  h2: ({ children }) => (
                    <h2 className="font-lora text-[32px] leading-[41px] font-semibold">
                      {children}
                    </h2>
                  ),
                },
                marks: {
                  color: (item) => (
                    <span style={{ color: item?.value?.hex }}>
                      {item?.text}
                    </span>
                  ),
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
